import styled from 'styled-components';
import { breakpoints, spacing } from '@naf/theme';
import { Grid, GridCol, GridRow } from '@naf/grid';
import { nafColor } from '@nafcore/theme';

export const HeaderContainer = styled.div`
  background-color: ${nafColor.signature.yellow10};
`;

export const StyledGrid = styled(Grid)`
  max-width: 1400px;
  margin: auto;
  gap: 128px ${spacing.space24} !important;
  padding: ${spacing.space120} ${spacing.space8};

  @media (max-width: ${breakpoints.s}) {
    padding-top: ${spacing.space56};
    padding-bottom: ${spacing.space48};
    row-gap: 64px !important;

    .reverse {
      order: -1;
    }
  }

  .check-icon {
    margin-right: ${spacing.space16};
    margin-left: ${spacing.space16};

    @media (max-width: ${breakpoints.s}) {
      margin-right: 4px;
      margin-left: 0;
    }
  }

  .flex-end {
    display: flex;
    justify-content: flex-end;
  }

  .do-not-display-mobile {
    @media (max-width: ${breakpoints.s}) {
      display: none !important;
    }
  }
`;

export const StyledGridRow = styled(GridRow)`
  align-items: center;

  @media (max-width: ${breakpoints.s}) {
    row-gap: 0 !important;
  }
`;

export const StyledRightGridCol = styled(GridCol)`
  grid-column-start: 8;

  @media (max-width: ${breakpoints.s}) {
    grid-column-start: 1;
  }
`;

export const StyledBodyTitleContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: ${spacing.space120};
  margin-left: 24px;
  margin-right: 24px;

  @media (max-width: ${breakpoints.s}) {
    padding-top: 0;
    margin-right: ${spacing.space16};
    margin-left: ${spacing.space16};
  }
`;

export const StyledBodyTextContainer = styled.div`
  max-width: 700px;
`;

export const VideoContainer = styled.div`
  background-color: ${nafColor.primary.spruce};
  color: ${nafColor.signature.white};
`;

export const StyledVideoGrid = styled(Grid)`
  max-width: 1400px;
  margin: auto;
  padding: ${spacing.space120} ${spacing.space8};

  @media (max-width: ${breakpoints.s}) {
    padding-top: ${spacing.space40};
    padding-bottom: ${spacing.space40};
  }
`;

export const StyledVideoGridCol = styled(GridCol)`
  display: flex;
  justify-content: center;
`;

export const BottomContainer = styled.div`
  background-color: ${nafColor.signature.yellow20};
`;

export const StyledBottomGrid = styled(Grid)`
  max-width: 1400px;
  margin: auto;
  padding: ${spacing.space80} ${spacing.space8};

  @media (max-width: ${breakpoints.s}) {
    padding-top: ${spacing.space48};
  }

  .flex-end {
    display: flex;
    justify-content: flex-end;
  }
`;

export const ImageWrapper = styled.div`
  margin: 0;
  line-height: 0;
  width: 100%;

  img,
  svg {
    width: 100%;
    border-radius: 8px;
  }

  @media (max-width: ${breakpoints.m}) {
    img {
      max-width: 100%;
      width: auto;
    }
  }
`;

export const StyledImageGridCol = styled(GridCol)`
  svg {
    width: 100%;
    height: auto;
  }
`;

export const StyledBottomImageGridCol = styled(GridCol)`
  display: flex;
  justify-content: center;

  svg {
    width: 75%;
    height: auto;
  }
`;
