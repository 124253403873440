/* eslint-disable no-unsafe-optional-chaining */
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { GridCol } from '@naf/grid';
import { Text, TextVariant } from '@naf/text';
import { nafColor } from '@nafcore/theme';
import { Button } from '@naf/button';
import { CheckCircle } from '@styled-icons/material/CheckCircle';
import { Layout } from '../../components/layout/Layout';
import { InternalSearchCardType } from '../../components/layout/InternalSearchMetaData';
import { useWindowLocation } from '../../hooks/useWindowLocation';
import { DynamicVideoPlayer } from '../../components/video-player/DynamicVideoPlayer';
import { actions as becomeAMemberActions } from '../../redux/modules/becomeAMember';
import * as S from './index.styles';
import BottomImage from './assets/bottomImage.svg';
import ResponsiveImage from '../../components/image/ResponsiveImage';
import useSelector from '../../redux/typedHooks';
import { ProductID } from '../../lib/ProductID';
import { LoaderContent } from '../LoaderPage';
import { useGTMDataLayer } from '../../hooks/useGTMDataLayer';
import { createEventPayload, useSendGTMEventOnce } from '../../hooks/useSendGTMEventOnce';
import { NAFGTMEvent } from '../../../../types/GTM/NAFGTMEvent';

export const BecomeAMemberIfYouHaveSmallKids = () => {
  const windowPath = useWindowLocation();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const dataLayer = useGTMDataLayer();

  useEffect(() => {
    dispatch(becomeAMemberActions.getBecomeAMember.request(undefined));
  }, [dispatch]);

  const membershipProductWithHelp = useSelector(
    (state) => state.becomeAMember.data.products[ProductID.MembershipWithRoadsideAssistance],
  );
  const membershipProductWithoutHelp = useSelector((state) => state.becomeAMember.data.products[ProductID.Membership]);

  const handleButtonClick = () => {
    dataLayer?.push({
      event: 'cta_click',
      cta_text: 'Bli medlem',
      cta_location: location.pathname,
      cta_url: '/bli-medlem',
      cta_hostname: window.location.hostname,
    });
  };

  const product = useSelector((state) => state.becomeAMember.data.products[ProductID.MembershipWithRoadsideAssistance]);
  useSendGTMEventOnce(
    product &&
      createEventPayload({
        event: NAFGTMEvent.viewItem,
        ecommerce: {
          items: [
            {
              item_id: membershipProductWithoutHelp.productNumber,
              quantity: '1',
              item_name: membershipProductWithoutHelp.productName,
              price: membershipProductWithoutHelp.productAppPrice[1]?.itemTotalPrice.toFixed(2),
              item_category2: 'Medlemskap',
              item_category3: 'Innmelding',
            },
            {
              item_id: '2020',
              quantity: '1',
              item_name: 'Veihjelp',
              price: (
                membershipProductWithHelp.productAppPrice[1]?.itemTotalPrice -
                membershipProductWithoutHelp.productAppPrice[1]?.itemTotalPrice
              ).toFixed(2),
              item_category2: 'Medlemskap',
              item_category3: 'Tilleggstjenester',
            },
          ],
        },
      }),
    [product],
  );

  return (
    <Layout
      title="Bli NAF-medlem"
      url={windowPath}
      gtmArgs={{
        page_type: 'ProductArticlePage',
        pageCategory: `naf-medlemskap`,
        contentId: 'naf-medlemskap',
      }}
      isHiddenFromSearch
      internalSearchMetaData={{
        cardType: InternalSearchCardType.Product,
        product: { title: 'Bli NAF-medlem' },
      }}
    >
      {membershipProductWithHelp && membershipProductWithoutHelp ? (
        <>
          <S.HeaderContainer>
            <S.StyledGrid>
              <S.StyledGridRow>
                <GridCol s="12" m="7" l="7" xl="7">
                  <Text variant={TextVariant.Display}>Bli NAF-medlem</Text>
                  <Text variant={TextVariant.ArticleText}>
                    <CheckCircle color={nafColor.primary.park} size={20} className="check-icon" />
                    NAF Veihjelp - uansett hvor du er.
                  </Text>
                  <Text variant={TextVariant.ArticleText}>
                    <CheckCircle color={nafColor.primary.park} size={20} className="check-icon" />
                    Medlemspriser på NAFs verkstedtjenester.
                  </Text>
                  <Text variant={TextVariant.ArticleText}>
                    <CheckCircle color={nafColor.primary.park} size={20} className="check-icon" />
                    Rabatter på forsikring, drivstoff og reise.
                  </Text>
                  <Text variant={TextVariant.Header2}>
                    Kr. {membershipProductWithHelp?.productAppPrice[1].itemTotalPrice},- for 12 måneder
                  </Text>
                  <Text style={{ fontSize: '1.25rem', fontWeight: 300 }}>
                    Kr. {membershipProductWithoutHelp?.productAppPrice[1].itemTotalPrice},- (uten veihjelp,
                    nøkkelforsikring og egenandelsforsikring for leiebil)
                  </Text>
                  <Button
                    style={{ marginTop: '6px', padding: '26px 80px' }}
                    variant="signature"
                    onClick={(e: any) => {
                      handleButtonClick();
                      e.preventDefault();
                      history.push('bli-medlem');
                    }}
                  >
                    Bli medlem
                  </Button>
                </GridCol>
                <S.StyledImageGridCol s="12" m="5" l="5" xl="5" className="flex-end do-not-display-mobile">
                  <S.ImageWrapper>
                    <ResponsiveImage imageId="Medlemskap/Produktsider/topImage_igmaoy" altText="NAF" />
                  </S.ImageWrapper>
                </S.StyledImageGridCol>
              </S.StyledGridRow>
            </S.StyledGrid>
          </S.HeaderContainer>

          <S.StyledBodyTitleContainer>
            <S.StyledBodyTextContainer>
              <Text variant={TextVariant.Header1} style={{ textAlign: 'center' }}>
                En trygg, enkel og økonomisk hverdag med NAF medlemskap
              </Text>
            </S.StyledBodyTextContainer>
          </S.StyledBodyTitleContainer>

          <S.StyledGrid>
            <S.StyledGridRow>
              <GridCol s="12" m="6" l="6" xl="6">
                <S.ImageWrapper>
                  <ResponsiveImage imageId="Medlemskap/Produktsider/productpage-image03_wxrmuq" altText="NAF" />
                </S.ImageWrapper>
              </GridCol>
              <GridCol s="12" m="1" l="1" xl="1" className="do-not-display-mobile">
                <div />
              </GridCol>
              <GridCol s="12" m="5" l="5" xl="5">
                <div style={{ maxWidth: 500 }}>
                  <Text variant={TextVariant.Header1}>Vi er der når du trenger oss </Text>
                  <Text>Du får tak i oss uansett årsak - på chat, på telefon, og e-post.</Text>
                  <ul>
                    <li>Ved kjøp og salg av bil.</li>
                    <li>Veihjelp i Norge og Europa.</li>
                    <li>Ved spørsmål om stort og smått.</li>
                  </ul>
                </div>
              </GridCol>
            </S.StyledGridRow>
            <S.StyledGridRow>
              <GridCol s="12" m="5" l="5" xl="5">
                <Text variant={TextVariant.Header1}>Din hverdagsreise skal ikke koste mer enn den må</Text>
                <Text>Med gode medlemspriser og rabatter trenger det heller ikke koste mer enn det må.</Text>
                <ul>
                  <li>10 % medlemsrabatt hos NAF Senter.</li>
                  <li>Inntil 22 % rabatt på forsikringer hos Gjensidige.</li>
                  <li>Rabatter på drivstoff, hotell og reiser.</li>
                </ul>
              </GridCol>
              <GridCol s="12" m="2" l="2" xl="2" className="do-not-display-mobile">
                <div />
              </GridCol>
              <GridCol s="12" m="5" l="5" xl="5" className="reverse">
                <S.ImageWrapper>
                  <ResponsiveImage imageId="Medlemskap/Produktsider/productpage-image01_fe9vcq" altText="NAF" />
                </S.ImageWrapper>
              </GridCol>
            </S.StyledGridRow>
            <S.StyledGridRow>
              <GridCol s="12" m="5" l="5" xl="5">
                <S.ImageWrapper>
                  <ResponsiveImage imageId="Medlemskap/Produktsider/productpage-image02_m2kpbr" altText="NAF" />
                </S.ImageWrapper>
              </GridCol>
              <S.StyledRightGridCol s="12" m="5" l="5" xl="5">
                <Text variant={TextVariant.Header1}>De yngste trafikantene prioriteres høyt</Text>
                <Text>Gode vaner og holdninger i trafikken bør etableres tidlig.</Text>
                <ul>
                  <li>Forbrukertester på utstyr for store og små.</li>
                  <li>Introduksjon av trafikkregler fra barnehagealder med Naffen.</li>
                  <li>Fokus på sikkerhet og tryggere miljø på skoleveiene.</li>
                  <li>Gode råd når de eldre barna skal ta lappen.</li>
                </ul>
              </S.StyledRightGridCol>
            </S.StyledGridRow>
          </S.StyledGrid>

          <S.VideoContainer>
            <S.StyledVideoGrid>
              <GridCol s="12" m="12" l="12" xl="12">
                <DynamicVideoPlayer
                  videoPlayerOptions={{
                    autoplay: false,
                    loop: false,
                    muted: true,
                  }}
                  video={{
                    duration: 45.013,
                    key: 'a8df0d8c-6def-4445-8f57-42308ef525b7',
                    publicId: 'Medlemskap/Produktsider/naf_45s_h264_webmaster_200109_v001_3',
                    altText: 'NAF',
                    srcSet: {
                      mp4: 'https://res.cloudinary.com/nafmedier/video/upload/so_1/v1702540807/Medlemskap/Produktsider/naf_45s_h264_webmaster_200109_v001_3.mp4',
                    },
                    version: 1702540807,
                  }}
                  image={{
                    publicId: 'naf_45s_h264_webmaster_200109_v001_3_vf6qeq',
                    srcSet: {
                      jpgLarge:
                        'https://res.cloudinary.com/nafmedier/image/upload/v1703244848/naf_45s_h264_webmaster_200109_v001_3_vf6qeq.jpg',
                    },
                    version: 1703244848,
                    caption: 'NAF',
                    source_caption: 'NAF',
                    source_altText: 'NAF',
                  }}
                />
              </GridCol>

              <S.StyledVideoGridCol s="12" m="12" l="12" xl="12">
                <S.StyledBodyTextContainer>
                  <Text variant={TextVariant.Header1} style={{ color: 'white' }}>
                    Vi er her for deg ❤️
                  </Text>
                  <Text style={{ color: 'white' }}>
                    Hver dag jobber vi for at våre nesten 500 000 medlemmer og deres kjære skal ferdes trygt i
                    trafikken. Våre tjenester sørger for at du og din bil er trafikksikre uansett hvor du er og hvor du
                    skal.
                  </Text>
                </S.StyledBodyTextContainer>
              </S.StyledVideoGridCol>
            </S.StyledVideoGrid>
          </S.VideoContainer>

          <S.BottomContainer>
            <S.StyledBottomGrid>
              <GridCol s="12" m="6" l="6" xl="6">
                <div style={{ maxWidth: 500 }}>
                  <Text variant={TextVariant.Header1}>Et medlemskap for deg, og de som betyr aller mest</Text>
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <div>
                      <Button
                        variant="signature"
                        onClick={(e: any) => {
                          handleButtonClick();
                          e.preventDefault();
                          history.push('bli-medlem');
                        }}
                      >
                        Bli medlem
                      </Button>
                    </div>
                    <div style={{ flex: 1 }}>
                      <Text style={{ marginLeft: 24, marginBottom: 0, marginTop: 0 }} variant={TextVariant.Header3}>
                        Kr. {membershipProductWithHelp?.productAppPrice[1].itemTotalPrice},- for 12 måneder
                      </Text>
                    </div>
                  </div>
                  <Text>
                    Kr. {membershipProductWithoutHelp?.productAppPrice[1].itemTotalPrice},- (uten veihjelp,
                    nøkkelforsikring og egenandelsforsikring for leiebil)
                  </Text>
                </div>
              </GridCol>
              <S.StyledBottomImageGridCol s="12" m="6" l="6" xl="6">
                <BottomImage />
              </S.StyledBottomImageGridCol>
            </S.StyledBottomGrid>
          </S.BottomContainer>
        </>
      ) : (
        <LoaderContent />
      )}
    </Layout>
  );
};
